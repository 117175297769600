import nhl from '../../assets/images/nhl.png'
import mlb from '../../assets/images/mlb.png'
import nba from '../../assets/images/nba.png'
import nfl from '../../assets/images/nfl.png'
import mls from '../../assets/images/mls.png'
import mlr from '../../assets/images/mlr.png'
import nwsl from '../../assets/images/nwsl.png'
import mlu from '../../assets/images/mlu.jpg'
import mlv from '../../assets/images/mlv.jpeg'

export const leagues = [
    {
        icon: nhl,
        leagueName: 'NHL'
    },
    {
        icon: mlb,
        leagueName: 'MLB'
    },
    {
        icon: nba,
        leagueName: 'NBA'
    },
    {
        icon: nfl,
        leagueName: 'NFL'
    },
    {
        icon: mls,
        leagueName: 'MLS'
    },
    {
        icon: mlr,
        leagueName: 'MLR'
    },
    {
        icon: mlv,
        leagueName: 'MLV'
    },
    {
        icon: mlu,
        leagueName: 'MLU'
    },
    {
        icon: nwsl,
        leagueName: 'NWSL'
    },

]