import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AiFillInstagram } from 'react-icons/ai';
import { FaFacebookF, FaFacebookMessenger, FaLinkedinIn } from 'react-icons/fa';
// import { FaXTwitter } from 'react-icons/fa6';


import Modale from '../modal';
import Cookies from 'universal-cookie';
import './style.css'

export default function Footer() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    return (
        <Container className='footerWrapper'>
            <Container>
                <Row className="linksContainer" sm={3} md={3} xs={2} >
                    <Col lg={2} md={3} sm={12} className="socialMediaWrapper" >
                        <Row className='socialMediaContainer'>
                            <div className='logo'></div>
                        </Row>
                    </Col>
                    <Col lg={6} md={2} sm={12}></Col>
                    <Col lg={4} md={7} sm={12}>
                        <p className='footerText'>
                            18+ Know When To Stop. Don’t Go Over The Top. Gamble Responsibly. Think! About Your Choices. Check Gambling Help on www.gamblinghelponline.org.au
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xxl={4} xl={4} lg={4}>
                        <p className='socialMedia'>
                            Social Media
                        </p>
                        {/* <AiFillInstagram color='fff' size={28} className='socialIcons' onClick={() => setModalShow(true)} />
                        <FaFacebookF color='fff' size={28} className='socialIcons' onClick={() => setModalShow(true)} />
                        <FaXTwitter color='fff' size={28} className='socialIcons' onClick={() => setModalShow(true)} /> 
                        <FaFacebookMessenger color='fff' size={28} className='socialIcons' onClick={() => setModalShow(true)} />
                        <FaLinkedinIn color='fff' size={28} className='socialIcons' onClick={() => setModalShow(true)} /> */}
                    </Col>
                    <Col xxl={4} xl={4} lg={4}>
                        <div className="copyRight" >
                            <p style={{ margin: 0, paddingTop: 0 }}>Copyright © 2023 - All right reserved.</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4}>
                        <div className='footerLinksContainer'>
                            <p onClick={() => setModalShow(true)}>Home</p>
                            <p onClick={() => setModalShow(true)}>Profile</p>
                            <p onClick={() => setModalShow(true)}>Withdraw</p>
                            <p onClick={() => setModalShow(true)}>Contact</p>
                            <p onClick={() => setModalShow(true)}>Deposit</p>
                            <p onClick={() => setModalShow(true)}>Profile</p>
                        </div>
                    </Col>
                </Row>

            </Container>

            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        null
                        :
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                            setShow={setModalShow}
                        />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                        setShow={setModalShow}
                    />
            }
        </Container>
    )
}



