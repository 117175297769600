import React from 'react'
import './style.css'
import Cookies from 'universal-cookie';
import { useState } from 'react';
import Modale from '../modal';
import { aboutData } from './data';
import Title from '../title';
const About = () => {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className='line2'>
            </div>
            <div className='nameTitle2'>
                Sports
            </div>
            <div className='line2'>
            </div>
            {aboutData?.map((data) =>
               <>
                    <div className='aboutCard'>
                        <div>  
                        <img className='cardImg' src={data.icon}></img>
                        </div>
                    </div>
               </>
               
            )}
            
        </>
    )
}

export default About