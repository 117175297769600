import React, { useEffect, useState } from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { getUserById } from '../../api';

export default function DropDown() {
    const cookie = new Cookies()
    const [user, setUser] = useState([])

    useEffect(
        () => {
            getUserById(cookie.get('id')).then(res => {
                setUser(res.data)
                // console.log(res.data)
            }).catch(error => console.log(error));
        },
        [])


    const logout = () => {
        cookie.remove('token')
        window.location.reload()
    }
    const popover = (
        <Popover id="popover-basic">
            <Popover.Body style={{ cursor: 'pointer' }} onClick={logout}>
                <p style={{ margin: 0 }}>Log out</p>
            </Popover.Body>
        </Popover>
    );
    return (
        <>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                <Button variant="none" style={{ color: 'white', marginRight: 32 }}>
                    <strong>{user?.balance} {user?.currency}</strong> - {user?.username}
                </Button>
            </OverlayTrigger>
        </>
    )
}
