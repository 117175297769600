import React from 'react';
import { images } from './data';
import { Col, Row } from 'react-bootstrap';
import './style.css'
import Cookies from 'universal-cookie';
import { useState } from 'react';

const ImageAnimation = () => {
    const cookie = new Cookies();
    // const [modalShow, setModalShow] = useStatete(false);
  return (
    <>
    <div className='ImageAnimationWrapper'>
      <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='ImageAnimationContainer'>
        {
          images?.map(image => (
            <Col xxl={3} xl={3} lg={3} style={{ padding: 0 }}>
              <img src={image.image} className={image.class} alt={image.image} />
            </Col>
          ))
        }
      </Row>
    </div >
    </>
  );
}

export default ImageAnimation;
