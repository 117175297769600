import image1 from '../../assets/images/image100-min.jpg'
import image2 from '../../assets/images/image101-min.jpg'
import image3 from '../../assets/images/image102-min.jpg'
import image4 from '../../assets/images/image103-min.jpg'
import image5 from '../../assets/images/image104-min.jpg'
import image6 from '../../assets/images/image105-min.jpg'
import image7 from '../../assets/images/image106-min.jpg'
import image8 from '../../assets/images/image108-min.jpg'
import image9 from '../../assets/images/image109-min.jpg'
import image10 from '../../assets/images/image110-min.jpg'
import image11 from '../../assets/images/image111-min.jpg'
import image12 from '../../assets/images/image112-min.jpg'

export const images = [
    {
        image: image1,
        class: 'animation1'
    },
    {
        image: image2,
        class: 'animation2'
    },
    {
        image: image3,
        class: 'animation3'
    },
    {
        image: image4,
        class: 'animation1'
    },
    {
        image: image5,
        class: 'animation2'
    },
    {
        image: image6,
        class: 'animation3'
    },
    {
        image: image7,
        class: 'animation1'
    },
    {
        image: image8,
        class: 'animation3'
    },
    {
        image: image9,
        class: 'animation1'
    },
    {
        image: image10,
        class: 'animation3'
    },
    {
        image: image11,
        class: 'animation2'
    },
    {
        image: image12,
        class: 'animation1'
    },
]