import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap'
import moment from 'moment';
import './style.css'
import Title from '../title';

export default function UserTable(props) {

    return (
        <>
        <Title title={props.title} />
            <Container fluid >
                <Row style={{ padding: 0 }}>
                    <Row className='gameTableContainer' >
                        {
                            props.data?.map((data, index) => (
                                <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                    <div className="gameCard">
                                        <div>
                                            <p>Home</p>
                                            <p>{data?.home_team}</p>
                                        </div>
                                        <div>
                                            <p>Away</p>
                                            <p>{data?.away_team}</p>
                                        </div>
                                        <div>
                                            <p>Date</p>
                                            <p>{" " + moment().format('L')}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }

                    </Row>
                </Row>
            </Container>
        </>
    )
}
