import React from 'react'
import './styles.css'
import Title from '../title'
import { Col, Container, Row } from 'react-bootstrap'
import reviews from '../../assets/images/review.jpg'
import RatingSlider from './ratingSlider'

const Reviews = () => {
    return (
        <div className='reviewsWrapper'>
            <Title title="Reviews" />
            <Container>
                <Row>
                    <Col xxl={7} xl={7} lg={7}>
                        <img src={reviews} className='reviewsImage' />
                    </Col>
                    <Col xxl={5} xl={5} lg={5}>
                        <div className='reviewsCardContainer'>
                            <div className='reviewsCard'>
                                <h3 className='reviewsCardTitle mb-4'>Customer Reviews</h3>
                                <div className='reviewsStarsContainer mb-1'>
                                    <span class="fa fa-star fa-lg checked"></span>
                                    <span class="fa fa-star fa-lg checked"></span>
                                    <span class="fa fa-star fa-lg checked"></span>
                                    <span class="fa fa-star fa-lg checked"></span>
                                    <span class="fa fa-star fa-lg checked"></span>
                                    <span className='ratingText'>4.7 out of 5</span>
                                </div>
                                <p className='ratingText mb-3'>20 customer ratings</p>

                                <div>
                                    <RatingSlider star="5" width='85%' />
                                    <RatingSlider star="4" width='10%' />
                                    <RatingSlider star="3" width='5%' />
                                    <RatingSlider star="2" width='0%' />
                                    <RatingSlider star="1" width='0%' />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Reviews