import { Row, Col } from 'react-bootstrap';
import './App.css';
import Navigation from './components/navigation'
import TableContainer from './components/table/tableContainer';
import Footer from './components/Footer';
import News from './components/news';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getResults } from '../src/api'
import BetTypes from './components/types';
import About from './components/about';
import ImageAnimation from './components/imageAnimation';
import USALeagues from './components/usaLeagues';
import Reviews from './components/reviews';


function App() {
  const [news, setNews] = useState([]);

  const [games, setGames] = useState([]);

  useEffect(() => {
    axios.get('/api/news')
      .then(response => {
        setNews(response.data.items);
      })
      .catch(error => console.log(error));

    getResults().then(async response => {
      if (response) {
        // console.log(response)
        setGames(response)
        // await setUpcoming(response.data[0].results)
        // await setCanceled(upcoming.filter((data) => data.status === 'canceled'))

      }
    })
      .catch(error => console.log(error));


  }, []);


  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navigation />
      <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
        <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
          <ImageAnimation />
        </Row>
      </Col>
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
            <News news={news} setNews={setNews} />
          </Row>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} >
          <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
            <BetTypes />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
          <USALeagues />
          <About />
        </Col>
        <Col xxl={8} xl={8} lg={8} md={8} sm={12}>
          <TableContainer games={games} />
        </Col>
      </Row>
      <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
        <Reviews />
      </Row>
      <Row>
        <Footer />
      </Row>

    </div >
  );
}

export default App;
