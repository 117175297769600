import React from 'react'
import './ratingSlider.css'
const RatingSlider = ({ star, width }) => {
    return (
        <div className='ratingSliderWrapper mb-3'>
            <span className='ratingSliderStar'>{star} star</span>
            <div className='ratingSliderContainer'>
                <div className='ratingSlider' style={{ width: `${width}` }}>
                </div>
            </div>
            <span className='ratingTextWidth'>{width}</span>
        </div>
    )
}

export default RatingSlider