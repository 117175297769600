import i from '../../assets/images/tennis_racket_balls_ss_0618_noexp_620x400.webp'
import ii from '../../assets/images/canvas-prints-basketball-ball.jpg'
import iii from '../../assets/images/GettyImages-1445444327.jpg'
import iv from '../../assets/images/bad.jpg'
import v from '../../assets/images/ping-pong.jpg'
import vi from '../../assets/images/Coupe-du-Monde-de-Rugby-2023-Une.png'
import vii from '../../assets/images/shutterstock_2278979355-scaled.jpg'
import viii from '../../assets/images/Baseball-bat.webp'
import ix from '../../assets/images/ImageForArticle_22980_16600577310868068.webp'


export const aboutData=[
    {
        id:1,
        icon: i,
    },
    {
        id:2,
        icon: ii,
    },
    {
        id:3,
        icon: iii,
    },
    {
        id:4,
        icon: iv,
    },
    {
        id:5,
        icon: v,
    },
    {
        id:6,
        icon: vi,
    },
    {
        id:7,
        icon: vii,
    },
    {
        id:8,
        icon: viii,
    },
    {
        id:9,
        icon: ix,
    },

]