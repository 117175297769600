// import { Popover } from 'bootstrap';
import React, { useState } from 'react'
import { Nav, Navbar, Container, Dropdown } from 'react-bootstrap'
import './style.css';
import DropDown from '../dropdown';
import Buttons from '../button';
import Cookies from 'universal-cookie';
import { navLinks } from './navLinks';
import Modale from '../modal';

export default function Navigation() {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);

    return (
        <div className="navContainer">
            <Container >
                <Navbar expand="lg" style={{ padding: 0, }}>
                    <Navbar.Brand href="/">
                        <div className="logo">
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle className="hamburgerButton" aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto" ></Nav>
                        {
                            cookie.get('token') ?
                                <>
                                    <Buttons text='History' kind="transparent2" history={true} />
                                    <Buttons text='Deposit' kind="transparent2" deposite={true} />
                                    <Buttons text='Withdraw' kind="transparent2" withdraw={true} />
                                    <Buttons text='Transactions' kind="transparent2" transaction={true} />
                                    <Buttons text='Profile' kind="transparent2" profile={true} />
                                    <DropDown />
                                </>
                                :
                                <>
                                    {
                                        navLinks?.map(link => (
                                            <Buttons text={link.link} kind="transparent2" onClick={() => setModalShow(true)} />
                                        ))
                                    }
                                    <div style={{ marginRight: 16 }}>
                                        <Buttons text='sign in' kind="primary" signin={true} />
                                    </div>
                                    <Buttons text='sign up' kind="transparent2" signup={true} />
                                </>

                        }
                    </Navbar.Collapse>
                </Navbar >
            </Container >
            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            contact={true}
                        />
                        :
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                        />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                    />

            }
        </div>
    )
}
