import { Button, Col, Container, Row } from 'react-bootstrap';
import './style.css'
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import Title from '../title';



export default function News({ news }) {

    return (
        <>
            <Container >
                <Title title="News" />
                <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className='slideShow'>
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            loop={true}
                            centeredSlides={true}
                            slidesPerView={'auto'}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            pagination={false}
                            modules={[EffectCoverflow, Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            {
                                news.map((data, index) => (
                                    <SwiperSlide className='swiper'>
                                        <div className='newsContainer'>
                                            <img src={data.enclosures[0].url} alt="news" className='newsImage' />
                                            <div className='newsBody'>
                                                <h4 className="newsBodyTitle">{data.title}</h4>
                                                <p className='newsDate' >Published: {" " + moment(data.published).format('L HH:MM')}</p>
                                                <Button className='newsReadMoreButton'>
                                                    <a
                                                        href={data.link}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Read More
                                                    </a>
                                                </Button>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                    {/* {
                        news?.map((data, index) => (
                            <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                                <div className='newsContainer'>
                                    <div className='newsImage'>
                                        <img src={data.enclosures[0].url} alt="news" width={200} height={150} />
                                    </div>
                                    <div className='newsBody'>
                                        <p className="newsBodyTitle">{data.title}</p>
                                        <div className='newsBodyDateButton'>
                                            <p className='newsDate' >{moment(data.published).format('L HH:MM')}</p>
                                            <Button className='newsReadMoreButton'>
                                                <a
                                                    href={data.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Read More
                                                </a>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    } */}
                </Row>
            </Container>
        </>
    )
}
