import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { login, getCusotmers } from '../../../api'
import Cookies from 'universal-cookie'
import LoginModal from '../../loginmodal';
import Buttons from '../../button';

export default function SignIn() {
    const [errorMessage, setErrorMessage] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [users, setUsers] = useState([]);
    const cookie = new Cookies()


    const getUsers = () => {
        getCusotmers().then(response => {
            setUsers(response.data)
        })
    }

    useEffect(async () => {
        getUsers()
    }, [])

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;
            const func = login(username, password)
            func.then(async (response) => {
                if (response.success) {
                    // setLogInMessage('Your account is suspended, Please contact customer support');
                    // alert(response.data.message);
                    // setModalShow(true);
                    cookie.set('token', response.token, { path: '/' })
                    cookie.set('firstname', response.data.firstName, { path: '/' })
                    cookie.set('username', response.data.username, { path: '/' })
                    cookie.set('id', response.data._id, { path: '/' })
                    cookie.set('balance', response.data.balance, { path: '/' })
                    cookie.set('email', response.data.email, { path: '/' })
                    cookie.set('currency', response.data.currency, { path: '/' })
                    cookie.set('wallet', response.data.wallet, { path: '/' })
                    window.location.reload()
                } else {
                    setErrorMessage(response.message)
                }
            })
        }
    })


    return (
        <Container className='conta'>
            <p style={{ color: 'red' }}>
                {errorMessage}
            </p>
            <Row>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12}>
                    <div className='line2'>
                    </div>
                    <div className='nameTitle2'>
                        Sign In
                    </div>
                    <div className='line2'>
                    </div>
                    {/* <p className='canvasSentence'>Sign in to access your account and explore our services.</p> */}
                    <Form onSubmit={formik.handleSubmit} >
                        {/* <Form.Label><h5 className='usernameTitle'>Username</h5></Form.Label> */}
                        <Form.Control
                            className='textInput'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.username}
                            type="text"
                            name="username"
                            placeholder="username"
                        />

                        {/* <Form.Label><h5 className='passwordTitle'>Password</h5></Form.Label> */}
                        <Form.Control
                            className='textInput'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            type="password"
                            name="password"
                            placeholder="password"
                        />
                        <div className='ModalFooterContainer' >
                            <Button type="submit" className='ModalFooterSubmit' >
                                Sign In
                            </Button>
                        </div>
                    </Form>
                    <LoginModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                    <div className='SwitchContainer'>
                        <div className='SwitchText'>
                            Do not have an account?
                        </div>
                        <div className='SwitchText2'>
                            Join our community today for exclusive benefits and opportunities - sign up now!
                        </div>
                        <Buttons text='sign up' kind="primary" signup={true} />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
