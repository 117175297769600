import React from 'react'
import './styles.css'

const Title = ({title}) => {
  return (
    <>
    <div className='line'>
        </div>
        <div className='nameTitle'>
            {title}
        </div>
        <div className='line'>
        </div>
    </>
  )
}

export default Title