import React from 'react'
import './styles.css'
import Cookies from 'universal-cookie';
import { useState } from 'react';
import Modale from '../modal';
import { leagues } from './data';
import Title from '../title';

const USALeagues = () => {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className='line2'>
            </div>
            <div className='nameTitle2'>
                Leagues
            </div>
            <div className='line2'>
            </div>
            {leagues?.map((data) =>
                <div className='usaLeagueContainer' onClick={() => setModalShow(true)}>
                    <div style={{ display: 'flex' }}>
                        <img src={data.icon} width={35} height={35} />
                        <p key={data.id} className='usaLeagueText'>{data.leagueName}</p>
                    </div>
                    <div>
                        8 matches
                    </div>
                </div>
            )}
            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        null
                        :
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                            setShow={setModalShow}
                        />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                        setShow={setModalShow}
                    />
            }
        </>
    )
}

export default USALeagues