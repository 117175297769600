import React, { useEffect, useState } from 'react'
import UserTable from './index'
import { Col } from 'react-bootstrap';
import shuffledArray from '../../hook/shuffledArray';
import { matches } from './data';

export default function TableContainer({ games }) {
    const [gameTitles, setGameTitles] = useState([]);

    useEffect(() => {
        const titles = matches.map((item) => item?.sport_title);
        const uniqueTitles = Array.from(new Set(titles));

        setGameTitles(uniqueTitles);
    }, [matches])


    var shuffledarray = shuffledArray(matches);


    return (
        <>
            {
                gameTitles?.map((item) => (shuffledarray.filter((data) => data?.sport_title === item).length > 0 &&
                    <Col key={item.id} lg={12} style={{ marginBottom: 16 }}>
                        <UserTable title={item} league={item} data={shuffledarray?.filter((data) => data?.sport_title === item)} />
                    </Col>
                ))

            }
        </>
    )
}
